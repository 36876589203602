@if (product()) {
  <div class="quantity">
    <label tuiLabel class="tui-space_bottom-4"
      >Quantity
      <tui-input-number
        [disabled]="disabled"
        tuiTextfieldSize="m"
        [min]="1"
        [max]="product().available_count"
        [tuiTextfieldLabelOutside]="true"
        [ngModel]="productQuantity"
        (ngModelChange)="onChange($event)"
      >
      </tui-input-number>
    </label>
    <button
      tuiIconButton
      size="s"
      type="button"
      appearance="secondary-destructive"
      class="tui-space_right-3 tui-space_bottom-3"
      iconStart="@tui.trash-2"
      (click)="delete.emit(true)"
      data-testid="remove-product-button"
    ></button>
    @if (product() && !hidePrice) {
      <p class="price">
        {{ itemTotal(product().revenue, 0) | displayPrice }}
      </p>
    }
  </div>
  @if (
    (product() && productQuantity === product().available_count) ||
    product().available_count < 3
  ) {
    <tui-error
      [error]="'Only ' + product().available_count + ' available'"
    ></tui-error>
  }
}
