import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { take } from 'rxjs';
import { TuiDialogService } from '@taiga-ui/core';
import { TuiConfirmService, TUI_CONFIRM } from '@taiga-ui/kit';
import { CartItem } from '../../models/index';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'cart-shared-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiConfirmService],
})
export class CartItemComponent {
  editRevenue = false;

  cartItem: CartItem | undefined;
  quantityControl = new FormControl(1);
  revenueControl = new FormControl(0);

  constructor(
    @Inject(TuiDialogService) private readonly dialogs: TuiDialogService,
  ) {
    this.quantityControl.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe((quantity) => {
        if (quantity && this.cartItem?.id) {
          this.update.emit({ ...this.cartItem, quantity });
        }
      });
  }

  @Input()
  set product(i: CartItem) {
    if (i) {
      this.cartItem = i;
      if (i.quantity !== this.quantityControl.value) {
        this.quantityControl.setValue(i.quantity, {
          onlySelf: true,
          emitEvent: false,
        });
      }

      if (i.revenue !== this.revenueControl.value) {
        this.revenueControl.setValue(i.revenue, {
          onlySelf: true,
          emitEvent: false,
        });
      }
    }
  }

  @Input() firstOrderFlow = false;

  @Input()
  set disableQuantity(d: boolean) {
    if (d) {
      this.quantityControl.disable();
    } else {
      this.quantityControl.enable();
    }
  }

  @Output() update = new EventEmitter<CartItem>();
  @Output() delete = new EventEmitter<CartItem>();

  prompt() {
    this.dialogs
      .open<boolean>(TUI_CONFIRM, {
        label: 'This will remove the item from your cart',
        data: {
          yes: 'Delete',
          no: 'Keep',
        },
      })
      .pipe(take(1))
      .subscribe((response) => {
        if (response) {
          this.delete.emit(this.cartItem);
        }
      });
  }

  updateRevenue() {
    if (!this.cartItem) {
      return;
    }

    this.editRevenue = false;
    const revenue: number = this.revenueControl.value || this.cartItem.revenue;
    this.update.emit({ ...this.cartItem, revenue });
  }

  updateRevenueControlDispatchUpdate(newPrice: number) {
    this.revenueControl.setValue(newPrice);
    this.updateRevenue();
  }
}
