import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  inject,
  input,
  OnInit,
  output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { selectCartItems } from '../../store/cart.reducer';
import { BehaviorSubject, filter, map, Observable } from 'rxjs';
import { CartItem } from '../../models';
import { BuyerRoutes } from '@chassis/buyer/shared';

interface Totals {
  total: number;
  [key: number]: {
    coreTotal: number;
    costTotal: number;
  };
}

@Component({
  selector: 'cart-shared-summary',
  templateUrl: './summary.component.html',
  styleUrl: './summary.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryComponent implements OnInit {
  private componentWidthSubject = new BehaviorSubject<number>(0);
  componentWidth$: Observable<number> = this.componentWidthSubject
    .asObservable()
    .pipe(
      map((v) => {
        if (v > 700) {
          return v;
        } else if (v > 450) {
          return 50;
        } else if (v > 250) {
          return 15;
        } else {
          return 10;
        }
      }),
    );

  public checkoutRoute = BuyerRoutes.checkout;

  private store = inject(Store);
  isCheckout = input.required<boolean>();

  data$: Observable<{ totals: Totals; cartItems: CartItem[] }> = this.store
    .select(selectCartItems)
    .pipe(
      filter((cartItems) => !!cartItems?.length),
      map((cartItems) => {
        return {
          totals: this.calculateOrderTotal(cartItems),
          cartItems: cartItems.map((c) => {
            return { ...c, current_core_charge: 2.0 };
          }),
        };
      }),
    );

  submit = output<boolean>();

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.updateComponentWidth();
  }

  itemTotal(cartItem: CartItem) {
    return (
      Number(cartItem.current_core_charge || 0) + Number(cartItem.cost_price)
    );
  }

  calculateOrderTotal(cartItems: CartItem[]): Totals {
    return cartItems.reduce(
      (total, cartItem) => {
        total[cartItem.id] = {
          coreTotal:
            cartItem.quantity * Number(cartItem.current_core_charge || 0),
          costTotal: cartItem.quantity * Number(cartItem.cost_price || 0),
        };

        total.total += this.itemTotal(cartItem) * cartItem.quantity;

        return total;
      },
      { total: 0 } as Totals,
    );
  }

  trackCartItem(cartItem: CartItem) {
    return cartItem.id;
  }

  @HostListener('window:resize')
  onResize() {
    this.updateComponentWidth();
  }

  private updateComponentWidth() {
    const width = this.elementRef.nativeElement.clientWidth;
    this.componentWidthSubject.next(width);
  }

  show(): void {
    // this.dialogs
    //   .open(new PolymorpheusComponent(ImagePreviewComponent, this.injector), {
    //     data: this.product,
    //     dismissible: true,
    //     closeable: true,
    //     size: 'm',
    //   })
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe();
  }
}
